





































import Vue from 'vue';

// Seems to be a bug with my linter. Says this import is unused but it def is...
// eslint-disable-next-line no-unused-vars
import { Route } from 'vue-router/types';

// Keep specific interfaces component-scoped, otherwise add to a @types global file
interface Step {
  index: number;
  title: {
    html: string;
    plain: string;
    style: string;
  };
  route?: Route;
}

export default Vue.extend({
  props: {
    title: String,
    steps: {
      type: Array as () => Step[],
    },
  },
  data() {
    return {
      router: this.$router,
    };
  },
  mounted() {
    // just incase we drop in from a link, otherwise we should mutate the store to change step
    if (this.currentRoute.name) {
      this.whichStep(this.currentRoute.name);
    }
  },
  methods: {
    whichStep: function (currentRoute: string): void {
      let currentRouteStepIndex: number | undefined;
      const stepsWithIndex = this.steps.map((step, i) => ({
        stepIndex: i,
        ...step,
      }));
      const step = stepsWithIndex.find(
        (step) => step.route?.name === currentRoute
      );
      currentRouteStepIndex = step?.stepIndex;

      if (Number.isInteger(currentRouteStepIndex))
        this.$store.commit('changeStepIndex', currentRouteStepIndex);
    },
  },

  watch: {
    // change route when step changes
    stepIndexStore: function (newVal: number): void {
      const step = this.steps[newVal];
      if (step && step.route) {
        this.$router.push(step.route.path);
      }
    },
    currentRoute: function (newRoute: Route) {
      if (newRoute.name) {
        this.whichStep(newRoute.name);
      }
    },
  },

  computed: {
    // getter and setter so we can map state to a v-model
    stepIndexStore: {
      get: function (): string {
        return this.$store.state.stepIndex;
      },
      set: function (newVal: number): void {
        this.$store.commit('changeStepIndex', newVal);
      },
    },
    currentRoute: function (): Route {
      return this.$route;
    },
  },
});
